import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';

const TelegramButton = () => {
  return (
    <button
      className="lg:hidden fixed bottom-44 right-2 w-14 h-14 rounded-full bg-green-500 bg-opacity-80 flex items-center justify-center shadow-lg z-50 transition-all hover:bg-blue-400 hover:bg-opacity-100 hover:scale-110 active:scale-95 animate-pulse hover:animate-none"
      aria-label="Telegram"
      onClick={() => {
        window.open('https://t.me/kamozaKZbot', '_blank');
      }}
    >
      <FaTelegramPlane size={28} className="text-white" />
    </button>
  );
};

export default TelegramButton;

import React from 'react';
import RouteWithHelmet from './components/RouteWithHelmet';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import ServicesPage from './pages/ServicesPage';
import NotFoundPage from './pages/NotFoundPage';

// Определение маршрутов
const routes = [
  {
    path: '/',
    title: 'Главная',
    element: (
      <RouteWithHelmet
        title="Главная - @Kamoza KZ"
        description="Создаем адаптивные сайты, Telegram-боты и мобильные приложения для бизнеса."
        keywords="создание сайтов, создание сайтов Казахстан, создать сайт, создание сайтов Астана, Разработка сайтов в Астане цены, веб-студия астана, Разработка сайтов под ключ, Разработка интернет магазина Астана, создание сайтов Алматы, создание сайтов Казахстан, крутой дизайн, адаптивный дизайн, крутой сайт, красивый сайт, веб-разработка, Telegram-боты, мобильные приложения, React, type script, TypeScript, JavaScript api, django, Django Rest Framework, DRF, python, posgresql, aiogram, kafka, nginx, Docker Compose, docker, redis, RabbitMQ, Celery, seo, продвижение сайтов, tailwind, поднять почтовый сервер, React Native, Redux Toolkit, Vite, сайт недорого, сайт быстро, Создание сайта цена казахстан, заказать сайт, Разработка сайтов Казахстан, Разработка сайтов недорого"
      >
        <HomePage />
      </RouteWithHelmet>
    ),
  },
  {
    path: '/about',
    title: 'О нас',
    element: (
      <RouteWithHelmet
        title="О нас - @Kamoza KZ"
        description="Узнайте больше о нашей компании, миссии и подходе к работе."
        keywords="Kamoza, Камоза, о компании, наша команда, миссия, цели"
      >
        <AboutPage />
      </RouteWithHelmet>
    ),
  },
  {
    path: '/contact',
    title: 'Контакты',
    element: (
      <RouteWithHelmet
        title="Контакты - @Kamoza KZ"
        description="Свяжитесь с нами для начала сотрудничества или получения кон                              сультации."
        keywords="контакты, поддержка, телефон, email, Kamoza, Камоза, ИП Kamoza"
      >
        <ContactPage />
      </RouteWithHelmet>
    ),
  },
  {
    path: '/services',
    title: 'Услуги',
    element: (
      <RouteWithHelmet
        title="Услуги - @Kamoza KZ"
        description="Ознакомьтесь с нашими услугами: создание сайтов, Telegram-бо                              тов, мобильных приложений."
        keywords="услуги, создание сайтов, Telegram-боты, мобильные приложения, р                              азработка"
      >
        <ServicesPage />
      </RouteWithHelmet>
    ),
  },
  {
    path: '*',
    element: (
      <RouteWithHelmet
        title="404 - Страница не найдена - @Kamoza KZ"
        description="Такой страницы не существует. Вернитесь на главную."
        keywords="404, страница не найдена, ошибка, Kamoza"
      >
        <NotFoundPage />
      </RouteWithHelmet>
    ),
  },
];

export default routes;

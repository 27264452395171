import { FaUserAlt, FaCogs, FaClipboardCheck, FaShieldAlt } from 'react-icons/fa';
import ModalButton from './ModalButton';
import LeadForm from './LeadForm';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function HeroSection() {
  return (
    <section className="relative w-full text-center py-4 px-0 sm:px-4 bg-gradient-to-r from-blue-100 via-blue-50 to-blue-100 dark:from-gray-900 dark:from-gray-800 dark:via-gray-900 dark:to-gray-800">
      <div className="container mx-auto max-w-6xl px-6">
        {/* Заголовок */}
        <h1 className="text-xl sm:text-[2.618rem] font-extrabold text-muted my-3 leading-tight">
          Создаём сайты, которые работают.
        </h1>
        {/* Подзаголовок */}
        <p className="text-lg sm:text-xl lg:text-[1.618rem] font-light text-muted mb-5">
          Быстро. Надёжно. Современно.
        </p>

        {/* Карточки */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          {[
            {
              icon: <FaUserAlt className="text-primary text-4xl" />,
              title: 'Индивидуальный подход',
              text: 'Мы понимаем Ваши задачи и создаём решения.',
            },
            {
              icon: <FaCogs className="text-primary text-4xl" />,
              title: 'Современные технологии',
              text: 'Быстрые, стабильные и адаптивные сайты.',
            },
            {
              icon: <FaClipboardCheck className="text-primary text-4xl" />,
              title: 'Прозрачность',
              text: 'Честные сроки, понятные условия.',
            },
            {
              icon: <FaShieldAlt className="text-primary text-4xl" />,
              title: 'Надёжность',
              text: 'Ваши данные защищены, работа сайта стабильна.',
            },
          ].map((item, index) => (
            <div
              key={index}
              className="card flex flex-col"
            >
              <div className="flex justify-center items-center mb-1">{item.icon}</div>
              <h3 className="text-lg font-bold text-muted mb-2">{item.title}</h3>
              <p className="text-base text-muted text-center">{item.text}</p>
            </div>
          ))}
        </div>

        {/* Кнопка */}
        <div className="mt-2 ">
          <ModalButton
            buttonText="Оставить заявку"
            buttonClassName="button button-primary animate-pulse hover:animate-none"
            modalContent={<LeadForm />}
          />
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
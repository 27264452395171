import React from 'react';
import { FiPhone } from 'react-icons/fi';

const CallButton = () => {
  return (
    <button
      className="lg:hidden fixed bottom-2 right-2 w-14 h-14 rounded-full bg-green-500 bg-opacity-80 flex items-center justify-center shadow-lg z-50 transition-all hover:bg-green-600 hover:bg-opacity-100 hover:scale-110 active:scale-95  animate-pulse hover:animate-none"
      aria-label="Call us"
      onClick={() => {
        window.location.href = 'tel:+77018839715';
      }}
    >
      <FiPhone size={28} className="text-white" />
    </button>
  );
};

export default CallButton;

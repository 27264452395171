import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = () => {
  return (
    <button
      className="lg:hidden fixed bottom-24 right-2 w-14 h-14 rounded-full bg-green-500 bg-opacity-80 flex items-center justify-center shadow-lg z-50 transition-all hover:bg-green-400 hover:bg-opacity-100 hover:scale-110 active:scale-95  animate-pulse hover:animate-none"
      aria-label="WhatsApp"
      onClick={() => {
        window.open('https://wa.me/77018839715', '_blank');
      }}
    >
      <FaWhatsapp size={28} className="text-white" />
    </button>
  );
};

export default WhatsAppButton;
